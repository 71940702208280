import React from 'react';
import { BrowserRouter as Router, Route, Link, useLocation, Routes } from 'react-router-dom';
import TeacherPage from './teacher';
import StudentPage from './student';
import { Helmet } from 'react-helmet';


function App() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
    <Router basename="/">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100vw', height: '100vh'}}>
        <Helmet>
          <title>클래스쪽지</title>
          <meta name="description" content="교실에서 간단하게 메시지를 보내세요." />
        </Helmet>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between', width:'100vw', height:'100vh', overflow:'auto'}}>
        <Navigation />

        <Routes>
          <Route path="/teacher" element={<TeacherPage />} />
          <Route path="/student" element={<StudentPage />} />
        </Routes>
        <div style={{ textAlign: 'center' }}>
          <p>내일의인간 junedevkr</p>
        </div>
      </div>      
    </div>
    </Router>
    </div>

  );
}

function Navigation() {
  const location = useLocation();

  if (location.pathname !== '/') {
    return null;
  }

  return (
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  height: '100vh', width: '100vw', margin: 'auto' }}>
  <p style={{ fontSize: '50px' }}>클래스 쪽지</p>
  <div style={{ border: '1px solid #ccc', padding: '20px', marginBottom: '10px', textAlign: 'center', width: '200px' }}>
    <Link to="/teacher" style={{ fontSize: '20px', textDecoration: 'none' }}>선생님</Link>
  </div>
  <div style={{ border: '1px solid #ccc', padding: '20px', marginTop: '10px', textAlign: 'center', width: '200px' }}>
    <Link to="/student" style={{ fontSize: '20px', textDecoration: 'none' }}>학생</Link>
  </div>
</div> 
 );
}


export default App;
